import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import wagmiConfig from './utils/walletConnect';
import Clipboard from "v-clipboard";
import {
	Overlay,Uploader,Button,DropdownMenu, DropdownItem,
	Tab, Tabs, Empty,
	Notify,
	RollingText,
	Toast,
	Dialog,
	showDialog,
	showNotify,
	showToast,
	NoticeBar,
	Swipe,
	SwipeItem,
	Icon,
	Progress,
	Field,
	Collapse, CollapseItem,
	Cell,
	RadioGroup,
	Radio,
	PasswordInput,
	NumberKeyboard,
	Popup,
	CellGroup,
	ActionSheet,
	CountDown, Checkbox
} from "vant";
import 'vant/lib/index.css';

import vueTouch from "vue-plugin-touch";
import touch from 'vue-directive-touch';
import { createI18n } from 'vue-i18n';
import '@/assets/styles/reset.scss';
import 'lib-flexible';

const i18n = createI18n({
  locale: localStorage.getItem("lang") || "en_us",
  messages: {
    'zh_cn': require('./assets/languages/zh_CN.json'),
    'zh_hk': require('./assets/languages/zh_HK.json'),
    'en_us': require('./assets/languages/en_US.json'),
    'th_th': require('./assets/languages/th_TH.json'),
    'ja_jp': require('./assets/languages/ja_JP.json'),
    'ko_kr': require('./assets/languages/ko_KR.json'),
    'ms_my': require('./assets/languages/ms_MY.json'),
    'pt_br': require('./assets/languages/pt_BR.json'),
    'ir_fa': require('./assets/languages/ir_fa.json'),
    'ar_ar': require('./assets/languages/ar_ar.json'),
    'db_de': require('./assets/languages/db_de.json'),
    'id_ind': require('./assets/languages/id_ind.json'),
    'vi_vn': require('./assets/languages/vi_VN.json')
  }
});

// import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi/vue'
// import { mainnet, polygon } from 'viem/chains'


// import { 
// 	getAccount,
// 	getChainId,
// 	reconnect,
// 	watchClient,
// 	watchAccount,
// 	watchChainId } from '@wagmi/core'
// // 1. Define constants
// const projectId = 'a5825827a80f6c5cc3059bb2b21c5417'

// // 2. Create wagmiConfig
// const metadata = {
//   name: 'Web3Modal',
//   description: 'Web3Modal Example',
//   url: 'https://web3modal.com', // origin must match your domain & subdomain
//   icons: ['https://avatars.githubusercontent.com/u/37784886']
// }

// const chains = [polygon]
// const config = defaultWagmiConfig({
//   chains,
//   projectId,
//   metadata,
//   enableCoinbase: false,
//   enableInjected: false,
//   auth: {
// 	  email: false, // default to true
// 	  // socials: ['google', 'x', 'github', 'discord', 'apple', 'facebook', 'farcaster'],
// 	  showWallets: true, 
// 	  walletFeatures: true 
// 	}
//   // ...wagmiOptions // Optional - Override createConfig parameters
// })

// reconnect(config)
// // 3. Create modal
// createWeb3Modal({
// 	wagmiConfig: config,
// 	projectId,
// 	themeMode: 'dark',
// 	enableOnramp: true,
// 	enableSwaps: true,
// 	enableAnalytics: true  
// })

// const unwatch = watchAccount(config, {
//   onChange(data) {
//     console.log('Account changed!', data)
//   },
// })
// unwatch()
// const unwatch2 = watchClient(config, {
//   onChange(client) {
//     console.log('Client changed!', client)
//   },
// })
// unwatch2()
// const chainId = readable(
//     getChainId(wagmiConfig),
//     (set) =>
//         watchChainId(wagmiConfig, { onChange: set }),
// );

// const account = readable(
//     getAccount(wagmiConfig),
//     (set) =>
//         watchAccount(wagmiConfig, { onChange: set }),
// );

// const provider = readable(
//     undefined,
//     (set) =>
//     watchAccount(wagmiConfig, {
//         onChange: async (account) => {
//             if (!account.connector) return set(undefined);
//             set(await (account.connector ? account.connector.getProvider() : undefined));
//         },
//     }),
// );


const app = createApp(App);
// app.config.errorHandler = (err, vm, info) => {
//   console.error(`Error: ${err.toString()}\nInfo: ${info}`);
//   showNotify({ type: 'danger', message: err.message || 'Unknown error' });
// };

// app.config.warnHandler = (msg, vm, trace) => {
//   console.warn(`Warn: ${msg}\nTrace: ${trace}`);
// }
app.use(router)
	.use(Toast)
	.use(Overlay)
	.use(Uploader)
	.use(RadioGroup)
	.use(Radio)
	.use(Button)
	.use(DropdownMenu)
	.use(DropdownItem)
	.use(Tab)
	.use(Tabs)
	.use(Empty)
	.use(CountDown)
	.use(Checkbox)
	.use(store)
	.use(i18n)
	.use(Clipboard)
	.use(RollingText)
	.use(Notify)
	.use(Dialog)
	.use(vueTouch)
	.use(touch)
	.use(Icon)
	.use(Progress)
	.use(Field)
	.use(NoticeBar)
	.use(Swipe)
	.use(PasswordInput)
	.use(NumberKeyboard)
	.use(wagmiConfig)
	.use(Popup)
	.use(ActionSheet)
	.use(Collapse)
	.use(CollapseItem)
	.use(Cell).use(CellGroup)
	.use(SwipeItem);


app.config.globalProperties.utils_moneyMark = function () {
  return 'USDT ';
};
app.config.globalProperties.showcover = false;

app.mount('#app');


