<template>
	<div id="app" v-touch:right="onSwipeLeft">
    <router-view v-if="loaded"/>
		       <!-- <div class="download_box" v-if="is_show_footer && app.is_disable === 'Y' && is_show_down_app">
            <a :href="app.app_link" class="down">
                <div class="down_left">
                    <span class="app_logo" v-bind:style="{'background-image':'url(' + app.image + ')'}"></span>
                    <div class="app_info">
                        {{app.text1}}<br>{{app.text2}}
                    </div>
                </div>
                <div class="right">App客户端</div>
            </a>
            <span class="close_down" @click="closeDownApp"></span>
        </div> -->
		<div v-if="is_show_footer" class="footer bglight">
			<router-link to="/" class="footer_item" :class="show_icon_type === 'index' ? 'footer_item_active' : ''">
				<!-- <span class="footer_icon footer_icon_home" /> -->
				<van-icon v-if="show_icon_type == 'index'" name="wap-home" size="25" />
				<van-icon v-if="show_icon_type != 'index'" name="wap-home-o" size="25"/>
				<p class="footer_info">
					{{$t('home.index')}}
				</p>
			</router-link>
			<router-link to="/trading" class="footer_item" :class="show_icon_type === 'trading' ? 'footer_item_active' : ''">
				<!-- <span class="footer_icon footer_icon_money" /> -->
				
				<van-icon v-if="show_icon_type == 'trading'" name="clock" size="25" />
				<van-icon v-if="show_icon_type != 'trading'" name="clock-o" size="25"/>
				<p class="footer_info">
					{{$t('home.trading')}}
				</p>
			</router-link>
			<router-link to="/vip" class="footer_item ismid" :class="show_icon_type === 'item' ? 'footer_item_active' : ''">
				<!-- <span class="footer_icon footer_icon_task" /> -->
				<div class="itimg">
					<van-icon v-if="show_icon_type == 'item'" name="diamond" size="35" class="icon"/>
					<van-icon v-if="show_icon_type != 'item'" name="diamond-o" size="35" class="icon"/>
				</div>
				<p class="footer_info">
					{{$t('home.item')}}
				</p>
			</router-link>
			<router-link to="/aboutus" class="footer_item" :class="show_icon_type === 'about' ? 'footer_item_active' : ''">
				<!-- <span class="footer_icon footer_icon_activity_1" /> -->
				<van-icon v-if="show_icon_type == 'about'" name="more" size="25" />
				<van-icon v-if="show_icon_type != 'about'" name="more-o" size="25"/>
				<p class="footer_info">
					{{$t('c3.about')}}
				</p>
			</router-link>
			<router-link to="/user" class="footer_item" :class="show_icon_type === 'user' ? 'footer_item_active' : ''">
				<!-- <span class="footer_icon footer_icon_user" /> -->
				<van-icon v-if="show_icon_type == 'user'" name="contact" size="25" />
				<van-icon v-if="show_icon_type != 'user'" name="contact-o" size="25"/>
				<p class="footer_info">
					{{$t('home.my')}}
				</p>
			</router-link>
		</div>
		<div v-show="real_name" class="real_name_mask">
			<div class="real_box">
				<div class="tit" />
				<p class="tips">
					{{$t('index.moneyset')}}
				</p>
				<div class="basic_btn" @click="real_name = false">
					{{$t('index.setnow')}}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { provide } from 'vue';

	import {
		Icon,
		showDialog,
		Dialog
	} from 'vant';
	import Fetch from "./utils/fetch";
	
	export default {
		name: 'App',
		data() {
			return {
				show_icon_type: 'index',
				real_name: false,
				is_show_down_app: true,
				is_show_footer: true,
				sync_timer: null,
				app: {},
				loaded:false
			}
		},
		updated() {
			document.body.scrollTop = 0
			document.documentElement.scrollTop = 0
		},
		mounted() {
			provide('footer', this.footer);
			this.start();
		},
		// setup() {
		// 	const footer = (type = 'index', show_footer = true) => {
		// 	const appElement = document.getElementById('app');
		// 	  if (type === 'walfare') {
		// 		appElement.style.paddingBottom = '0';
		// 	  } else {
		// 		appElement.style.paddingBottom = '1.333333rem';
		// 	  }
		// 	  this.show_icon_type = type;
		// 	  this.is_show_footer = show_footer;
		// 	};
		// 	provide('footer', footer);
		// },
		methods: {
			onSwipeLeft (even,start,end) {
				console.log();
				if(end['X']-start['X']>60){
					this.$router.go(-1);
				}
			},
			bigNumeber(str) {
				var str = str.toString();
				var strs = str.split(".");
				var s1 = strs[0] || 0;
				var s2 = strs[1] || "00"
				return `<span class="bigNum">${s1}</span><span>.</span><span>${s2}</span>`;
			},
			numFilter(value) {
				let transformVal = parseFloat(value).toFixed(3)
				let realVal = transformVal.substring(0, transformVal.length - 1)
				return parseFloat(realVal)
			},
			footer: function(type = 'index', show_footer = true) {
				if (type == 'walfare') {
					document.getElementById('app').style.paddingBottom = '0';
				} else {
					document.getElementById('app').style.paddingBottom = '1.333333rem';
				}
				this.show_icon_type = type;
				this.is_show_footer = show_footer;
			},
			start() {
				Fetch("/index/webconfig").then((r) => {
					if(r.data){
						this.app = r.data;
						window.document.title = r.data.title;
						this.$i18n.locale = r.data.lang;
						localStorage.setItem("lang", r.data.lang);
						localStorage.setItem("moneyMark", r.data.money_mark);
					}
					this.loaded = true;
				});

				setTimeout(()=>{
				  this.sync()
				},2000)
			},
			closeDownApp() {
				this.is_show_down_app = false;
			},
			sync() {
				Fetch("/index/sync", {}, null, false).then((r) => {
				  if (r.data>0) {
					this.openNoticeDialog(r.data);
				  }
				});
			},
			close() {
				this.show_anwser = false;
			},
			openNoticeDialog(num) {
				showDialog({
					confirmButtonText: this.$t('utils.confirm'),
					cancelButtonText: this.$t('utils.cancel'),
					title: this.$t('notice.message'),
					message: num+this.$t('notice.unRead')
				});
			},
		}
	}
</script>
<style lang="less">

	// @import "./assets/font/font.css";
	.kefu {
		width: 60px;
		height: 60px;
		position: fixed;
		z-index: 10;
		right: 13px;
		bottom: 80px;
		transition: all 1s;
		opacity: 1;
		transform: translateX(0);
		z-index: 80;

		img {
			width: 100%;
			height: 100%;
		}
	}

	.kefu.kefu_hide {
		transform: translateX(70%);
		opacity: 0.5;
	}

	.numberFont {
		font-family: barlow !important;
		font-size: 14px !important;
		font-weight: bold !important;
		span:nth-of-type(3) {
			font-size: 14px !important;
		}
		span:nth-of-type(4) {
			font-size: 12px !important;
		}
	}

	html,
	body {
		width: 100%;
		height: 100%;
		overflow-y: scroll;
	}

	html::-webkit-scrollbar,
	body::-webkit-scrollbar {
		width: 0px;
		height: 0px;
	}

	body {
		margin: 0;
	}

	.bigNum {
		font-size: 18px !important;
	}

	.fh {
		font-size: 12px !important;
	}

	.larfont .fh {
		margin-top: 3px;
	}

	.wan {
		font-size: 14px;
		margin-top: 2px;
	}

	.numberFontBold {
		span:first-child {
			font-size: 12px;
			margin-top: 4px;
		}

		span:last-child {
			margin-top: 2px;
		}
	}

	#app {
		padding-bottom: 65px !important;
		min-height: 100%;
		height: auto;
		width: 100%;
		max-width: 375px;
		background-color: #FAFAFA;
		max-width: 720px;
		margin: auto;
		// background-color: #00c0ff;
		// background: url(./views/images/shop2/1.png) no-repeat top;
		// background: linear-gradient(180deg, #2F8886 0%, #28475C 100%);
		background-size: 100% 100%;
		background: #191d22;
		color: #727d91;
		
	}
	@media screen and (min-width: 768px) {
		#app,#app .footer {
			width: 500px !important;
		}
		
	}

	#app .download_box {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		position: fixed;
		margin: 0 auto;
		left: 0;
		right: 0;
		height: 45px;
		width: 100%;
		max-width: 375px;
		background: rgba(0, 0, 0, 0.5);
		bottom: 50px;
		padding: 0 3.6%;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		z-index: 10;
	}

	#app .download_box .down {
		-webkit-box-flex: 1;
		-ms-flex: 1 1 auto;
		flex: 1 1 auto;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		padding-right: 10px;
	}

	#app .download_box .down .down_left {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}

	#app .download_box .down .down_left .app_logo {
		display: inline-block;
		width: 40px;
		height: 40px;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;
		background-image: url(./views/images/home/app_logo.svg);
		margin-right: 14px;
		;
	}

	#app .download_box .down .down_left .app_info {
		font-size: 12px;
		color: #fff;
		line-height: 1.5;
		;
	}

	#app .download_box .down .right {
		background: #D8232C;
		padding: 5px 5px;
		color: #ffffff;
		border-radius: 5px;
		font-size: 12px;
	}

	#app .download_box .close_down {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 20px;
		flex: 0 0 20px;
		height: 45px;
		;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;
		background-image: url(./views/images/home/close.svg);
	}

	#app .footer {
		position: fixed;
		margin: 0 auto;
		left: 0;
		right: 0;
		-webkit-box-flex: 0;
		-ms-flex: 0 0 48px;
		flex: 0 0 48px;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-pack: distribute;
		justify-content: space-around;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		// background-color: rgb(235, 251, 248);
		width: 100%;
		max-width: 750px;
		bottom: 0;
		height: 60px;
		z-index: 100;
	}

	#app .footer .footer_item {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		cursor: pointer;
		color: #727d91;
		width: 25%;
		text-align: center;
	}

	#app .footer .footer_item .footer_icon {
		display: inline-block;
		width: 26px;
		height: 26px;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;
		margin-bottom: 6px;
	}

	#app .footer .footer_item .footer_icon.footer_icon_home {
		background-image: url(./views/images/shop2/home_line.png);
	}

	#app .footer .footer_item .footer_icon.footer_icon_task {
		background-image: url(./views/images/shop2/funds_line.png);
	}

	#app .footer .footer_item .footer_icon.footer_icon_cart {
		background-image: url(./views/images/common/activity.png);
	}
	#app .footer .footer_item .footer_icon.footer_icon_money {
		background-image: url(./views/images/shop2/item_1.png);
	}
	#app .footer .footer_item .footer_icon.footer_icon_activity_1 {
		background-image: url(./views/images/shop2/share_line.png);
	}
	#app .footer .footer_item .footer_icon.footer_icon_user {
		background-image: url(./views/images/shop2/mine_line.png);
	}

	#app .footer .footer_item .footer_info {
		font-size: 12px;
		font-weight: bold;
	}

	#app .footer .footer_item.footer_item_active {
		color: #ffd200;
	}

	#app .footer .footer_item.footer_item_active .footer_icon.footer_icon_home {
		background-image: url(./views/images/shop2/home_green.png);
	}

	#app .footer .footer_item.footer_item_active .footer_icon.footer_icon_task {
		background-image: url(./views/images/shop2/funds_green.png);
	}
	#app .footer .footer_item.footer_item_active .footer_icon.footer_icon_money {
		background-image: url(./views/images/shop2/item_cur_1.png);
	}
	#app .footer .footer_item.footer_item_active .footer_icon.footer_icon_activity_1 {
		background-image: url(./views/images/shop2/share_green.png);
	}
	
	
	#app .footer .footer_item.footer_item_active .footer_icon.footer_icon_cart {
		background-image: url(./views/images/shop2/share_green.png);
	}

	#app .footer .footer_item.footer_item_active .footer_icon.footer_icon_user {
		background-image: url(./views/images/shop2/mine_green.png);
	}

	.red_top_bg {
		max-width: 750px;
		background: #FFF;
		overflow: hidden;
		background-color: #1f242b;
		background-size: 100% 100%;
		position: relative;
		width: 100%;
		height: 50px;
		color: #ffd200;
	}

	.top_right {
		position: absolute;
		font-size: 13px;
		line-height: 18px;
		color: #ffd200;
		top: 10px;
		right: 10px;
	}

	.limit {
		height: 20px;
		line-height: 20px;
		border-radius: 10px;
		background: rgba(241, 34, 17, 0.08);
		color: #f12211;
		text-align: center;
		padding: 0 7px;
		border: 1px solid #f12211;
		font-size: 12px;
	}

	.gray_btn {
		height: 20px;
		align-items: center;
		background: rgba(255, 255, 255, .4) !important;
		font-size: 12px;
		text-align: center;
		color: #e5e5e5 !important;
		border-radius: 10px;
		padding: 0 9px;
	}
	.rechargebtn{
		 // background: linear-gradient(270deg,#ffd543,#0c7f9e);
		 
		 background: linear-gradient(1turn, #FF6666, #ffd543);
	}
	.withdrawbtn{
		// background: linear-gradient(270deg,#ffd543,#3b58a4);
		
		background: linear-gradient(1turn, #339999, #ffd543);
	}
	.big_tit {
		position: absolute;
		height: 25px;
		font-size: 16px;
		font-weight: 500;
		line-height: 25px;
		top: 12px;
		left: 50%;
		transform: translateX(-50%);
	}

	.sub_btn {
		background: linear-gradient(1turn, #3b58a4, #ffd543);
		height: 30px;
		font-size: 12px;
		color: #FFFFFF;
		text-align: center;
		width: 94px;
		border-radius: 5px;
	}

	.remove_btn {
		background: linear-gradient(117deg, rgba(254, 166, 0, 1) 0%, rgba(255, 188, 0, 1) 100%);
		height: 36px;
		line-height: 36px;
		font-size: 12px;
		color: #FFFFFF;
		text-align: center;
		width: 94px;
		border-radius: 18px;
	}

	.home_tit {
		width: 100%;
		height: 22px;
		line-height: 22px;
		display: flex;
		align-items: center;
		justify-content: center;

		.line {
			width: 75px;
			height: 2px;
		}

		h5 {
			font-size: 16px;
			line-height: 22px;
			color: #000000;
			margin: 0 10px;
			font-weight: bold;
		}
	}

	.van-popup {
		max-width: 100%;
		margin: 0 auto;
		right: 0;
	}

	.back_left {
		width: 20px;
		height: 20px;
		background: url(./views/images/item/back.png) no-repeat center center;
		background-size: auto 100%;
		margin: 12px 0 0 8px;
	}
	.getcode,.btn {
		background: #ffd200 ;
		color: #282828 ;
	}
	.basic_btn {
		width: 100%;
		border-radius: 5px;
		color: #fff;
		text-align: center;
		background: #ffd200!important;
		color: #282828!important;
		height: 38px;
		line-height: 38px;
		font-size: 16px;
	}

	.basic_btn.no_touch {
		color: rgba(255, 255, 255, 0.5);
	}
	.basic_wrap .form{
		border-color: #D0B8A8;
		background-image: linear-gradient(to right bottom, rgb(37, 75, 98) 0%, rgb(29, 62, 83) 100%);
	}
	.flex_center {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.wrap_box {
		// width: 100%;
		background: rgba(255, 255, 255, 1);
		box-shadow: 0px 2px 9px 2px rgba(160, 160, 160, 0.15);
		border-radius: 6px;
	}

	.eye_bi {
		width: 21px;
		height: 25px;
		background: url(./views/images/user/eye2.png) no-repeat center center;
		background-size: 100% auto;

		&.eye {
			background: url(./views/images/user/eye1.png) no-repeat center center;
			background-size: 100% auto;
		}
	}

	.real_name_mask {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 30;
		background-color: rgba(0, 0, 0, 0.95);

		.real_box {
			width: 272px;
			height: 185px;
			background: rgba(255, 255, 255, 1);
			border-radius: 8px;
			position: absolute;
			top: 50%;
			left: 0;
			right: 0;
			margin: -92px auto 0 auto;

			.tit {
				width: 77px;
				height: 67px;
				position: absolute;
				top: -35px;
				left: 50%;
				transform: translateX(-50%);
				background: url(./views/images/user/tit.png) no-repeat center center;
				background-size: 100% 100%;
			}

			.tips {
				width: 185px;
				height: 50px;
				text-align: center;
				font-size: 18px;
				color: #000000;
				line-height: 25px;
				position: absolute;
				top: 49px;
				left: 50%;
				transform: translateX(-50%);
			}

			.basic_btn {
				width: 225px;
				position: absolute;
				left: 0;
				right: 0;
				margin: 0 auto;
				bottom: 15px;
			}
		}
	}

	.contract_box img {
		width: 100%;
	}
	.kefu_img{
		border-radius: 80px;
	}
	.logo{
		width: 30vw;
		height: 30vw;
		margin-left: auto;
		margin-right: auto;
		.logoimg{
			height: 100%;
			width: 100%;
		}
	}
	.logourl{
		font-size: 26px;
		line-height: 15vw;
		color: #FFF;
		text-align: center;
	}
	.item input{
		color: #FFFFFd;
	}    
	.head-tabs {
	    height: 50px;
	    padding: 15px;
	    background: #1f242b;
	    white-space: nowrap;
		border-radius: 2.66667vw;
		text-align: center;
		width: 95%;
		margin: 10px auto;
		color: #ffd200;
		.tab-item {
		    z-index: 0;
		    position: relative;
		    height: 16px;
		    line-height: 16px;
		    font-size: 14px;
		    color: #fffffd;
		    transition: all .3s ease;
		}
		.tab-item:after {
		    z-index: -1;
		    content: "";
		    position: absolute;
		    left: 0;
		    right: 0;
		    bottom: 0;
		    height: 4px;
		    border-radius: 2px;
			
		}
		.active {
		    font-size: 16px;
		    color: #fffffd;
			:after {
			    background: #ff8c46;
			}
		}
		.tab-item.active:after {
		    background: #ff8c46;
		}
		.tab-item+.tab-item {
		    margin-left: 20px;
		}
	}
	
	
	h3.box-title{
		margin: 4vw 0 5.33333vw 6.93333vw;
		font-size: 4.26667vw;
		font-weight: 400;
		color: #ffd200;
	}
	h2.page-title{
		font-size: 25px;
		font-weight: 700;
		color: #ffd200;
		padding: 2vh 21px;
	}
	h3.page-title{
		color: #727d91;
		font-weight: 400;
		padding: 2vh 21px;
		padding-top: 0;
	}
	.ismid{
		.itimg{
			position: relative;
			z-index: 1;
			margin: -2em auto 0;
			width: 5em;
			height: 5em;
			border-top: 1px solid #191d22;
			border-radius: 100%;
			background: #1f242b;
			.icon{
				width: 1.6em;
				height: 1.6em;
				padding-top: 0.3em;
			}
		}
		
	}
	.bgdeep {
		color: #282828;
		background: linear-gradient(220.55deg, #FFBB89 0%, #554e80 100%) !important;
	}
	.bglight {
		background: #1f242b;
		color: #727d91;
	}
	.gun{
		background: linear-gradient(220.55deg, #FFBB89 0%, #7B6AE0 100%) !important;
	}
	
</style>
