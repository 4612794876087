
import { createStore } from 'vuex';
import header from './header';
import loading from './loading';
import config from './config';
import step from './step';
import home from './home';
import user from './user';

export default createStore({
  modules: {
    header,
    loading,
    config,
    step,
    home,
    user
  }
});
