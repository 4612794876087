import axios from 'axios';
import qs from 'qs';
import { showToast, showDialog, showNotify,showLoadingToast,closeToast } from 'vant';
// import { useRouter } from 'vue-router';
import router from '@/router'
import api from "../interface/index";

// 初始化 Vant
// const router = useRouter();  // 使用 Vue 3 的 router hook

let loadingCount = 0;

export const showLoading = () => {
  if (loadingCount <= 0) {
    showLoadingToast({
      duration: 20000,
    });
  }
  loadingCount++;
};

export const hideLoading = () => {
  loadingCount--;
  if (loadingCount <= 0) {
    closeToast();
    loadingCount = 0;
  }
};

let ssid = localStorage.getItem('ssid');

export default async (url, data, opt, isLoad = false) => {
  let lang = localStorage.getItem("lang") || "en_us";

  if (isLoad) {
    showLoading();
  }

  url = api.commonUrl + "/api" + url;
  const set = {
    method: 'post',
    ...opt
  };
  var token = localStorage.getItem('token');
  var language = localStorage.getItem("lang");
  return (set.method === 'post' ? axios.post(url, {
    ...data,
    token,
    language
  }) : axios.get(url + '?' + qs.stringify({
    ...data,
    token
  }))).then(r => {

    if (isLoad) {
      hideLoading();
    }

    if (r.status === 200 && r.data.code) {
      return r.data;
    }

    if (r.data.code === 0) {
      return r.data;
    }

    throw {
      msg: 'Network error, please try again later or switch networks!'
    };

  }).then(r => {

    if (r.code === 1) {
      return r
    } else {
      if (r.code === 0) {
        throw {
          msg: r.info
        }
      }
      if (!r.code) {
        throw {
          msg: 'Network error, please try again later or switch networks!'
        }
      }
      // if (r.code === 406) {
      //   router.push('/setpaypwd');
      // }

      if (r.code === 405) {
        // const current = {
        //   ...router.currentRoute
        // };
        // router.push('/authEmail?redirect=' + current.path + "?id=" + current.query.id + "&money=" + current.query.money);
      }

      // if (r.code === 410) {
      //   router.push('/setpaypwd');
      // }

      if (r.code === 403 || r.code === 401) {
		localStorage.removeItem('logintime');
		localStorage.removeItem('token');
		localStorage.removeItem('userAddress');
		return '';
        // if (r.code === 401) {
        //   localStorage.setItem('reset_time', 0);
        // }
		// router.replace('/login');
		// console.log(router);
        // const current = {
        //   ...router.currentRoute
        // };
        // if (current && current.path !== '/' && !(['/', '/reg', '/forgetpwd'].includes(current.path))) {
        //   if (current.path !== '/login') {
        //     router.replace('/login?redirect=' + current.path)
        //   }
        // }
      }
    }

    throw r;

  }).catch(r => {

    if (isLoad) {
      hideLoading();
    }
    var message = 'Network error, please try again later or switch networks!';

    if (r.info) {
      message = r.info[lang];
    }

    if (r.msg) {
      message = r.msg[lang];
    }
    if (isLoad && (r.info || r.msg)) {
      showDialog({
        message: message,
        confirmButtonText: 'Confirm'
      });
    }
    // 使用 showNotify 来显示消息
    showNotify({ type: 'warning', message: message });
    // throw '';
	return r;
  });
}
